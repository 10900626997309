import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
export const controlBycontrolId = createSelector(
    (state: RootState) => state.controlDetailPage,
    (controlDetailData) => controlDetailData?.controlBycontrolId || {},
);
export const riskBycontrol = createSelector(
    (state: RootState) => state.controlDetailPage,
    (controlDetailData) => controlDetailData.riskBycontrolID || [],
);

export const getLoadingState = createSelector(
    (state: RootState) => state.controlDetailPage,
    (controlDetailData) => controlDetailData.isLoading,
);

export const getControlDetailBreadcrumb = createSelector(
    (state: RootState) => state.risk,
    (risk) => risk.breadcrumbData || [],
);
