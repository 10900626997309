import { useEffect, useRef, useState } from 'react';
import './customDropDown.scss';
import { NexusCheckbox } from '@nexus/react';
import dropDownIcon from '@nexus/core/dist/assets/icons/action/ic_arrow_drop_down_24px.svg';
import IconComponent from '../icon/icon';

interface CustomDropDownProps {
    options: any[];
    placeholder?: string;
    optionTitle?: string;
    handleSelect: (selectedItem: any) => void;
    selectedValues: string[];
}

const CustomDropDown: React.FC<CustomDropDownProps> = ({
    options,
    placeholder,
    optionTitle,
    handleSelect,
    selectedValues,
}) => {
    const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropDown = () => {
        setIsDropDownOpen(!isDropDownOpen);
    };

    const handleclickoutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsDropDownOpen(false);
        }
    };

    useEffect(() => {
        if (isDropDownOpen) {
            document.addEventListener('mousedown', handleclickoutside);
        } else {
            document.removeEventListener('mousedown', handleclickoutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleclickoutside);
        };
    }, [isDropDownOpen]);

    //format display value
    const getDisplayValue = () => {
        let localSelectedFilters = selectedValues.filter((item) => {
            const titlesArray = options.map((item) => item.value.toUpperCase());
            return titlesArray.includes(item.toUpperCase());
        });

        if (localSelectedFilters.length === 0) {
            return placeholder || <span className='selected-title'>selected options</span>;
        } else if (localSelectedFilters.length === options.length) {
            return (
                <span className='selected-title' style={{ marginRight: '5px' }}>
                    {optionTitle} <span className='selection-bold'>All</span>
                </span>
            );
        } else if (localSelectedFilters.length > 1) {
            return (
                <span className='selected-title' style={{ marginRight: '5px' }}>
                    {optionTitle} <span className='selection-bold'>{localSelectedFilters.length} selected</span>
                </span>
            );
        }
        return localSelectedFilters.map((option: any, index: number) => (
            <span className='selected-title' key={index} style={{ marginRight: '5px' }}>
                {optionTitle} {options.filter((item) => item.value.toLowerCase() === option.toLowerCase())[0]?.option}
            </span>
        ));
    };
    return (
        <div className='custom-dropdown' ref={dropdownRef}>
            <div className='dropdown-header' onClick={toggleDropDown}>
                <span className='item-selected'>{getDisplayValue()}</span>
                <span className='dropdown-icon'>
                    {' '}
                    <IconComponent src={dropDownIcon} />
                </span>
            </div>
            {isDropDownOpen && (
                <div className='dropdown-menu'>
                    <label className='dropdown-item'>
                        <NexusCheckbox
                            className='checkbox'
                            onClick={(e: any) => {
                                handleSelect({
                                    checked: e.target.checked,
                                    id: 'all',
                                    value: 'all',
                                });
                            }}
                            checked={selectedValues?.length === options.length}
                            key={'all'}
                        ></NexusCheckbox>
                        <span>{'All'}</span>
                    </label>
                    {options.map((option: any) => (
                        <label className='dropdown-item'>
                            <NexusCheckbox
                                className='checkbox'
                                onClick={(e: any) => {
                                    handleSelect({
                                        checked: e.target.checked,
                                        id: option?.id,
                                        label: option?.label,
                                        value: option?.value,
                                    });
                                }}
                                checked={selectedValues?.includes(option?.value)}
                                key={option?.id}
                            ></NexusCheckbox>
                            <span>{option.option}</span>
                        </label>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CustomDropDown;
