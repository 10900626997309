import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
export const getRiskDataSelector = createSelector(
    (state: RootState) => state.inventory,
    (inventoryData) => inventoryData.risksData || { content: [] },
);
export const getProcessDataSelector = createSelector(
    (state: RootState) => state.inventory,
    (inventoryData) => inventoryData.processData || { content: [] },
);
export const getControlsDataSelector = createSelector(
    (state: RootState) => state.inventory,
    (inventoryData) => inventoryData.controlsData || { content: [] },
);
export const getInventoryFilters = createSelector(
    (state: RootState) => state.inventory,
    (inventoryData) => inventoryData.filters || {},
);
export const getInventoryBreadcrumb = createSelector(
    (state: RootState) => state.inventory,
    (inventoryData) => inventoryData.breadcrumbData || [],
);
export const isInventoryScreenLoading = createSelector(
    (state: RootState) => state.inventory,
    (inventoryData) => inventoryData.isLoading,
);
