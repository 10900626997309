import { useEffect, useState } from 'react';
import CardComponent from 'app/components/nds/card/card';
import AccordionComponent from 'app/components/nds/accordion/accordion';
import './controlDetails.scss';
import { useTranslation } from 'react-i18next';
import CardDetails from 'app/components/card/cardDetails';
import BreadcrumbComponent from 'app/components/nds/breadcrumb/breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import {
    getLoadingState,
    getControlDetailBreadcrumb,
    controlBycontrolId,
    riskBycontrol,
} from './redux/controlDetailsSelectors';
import BackArrow from '@nexus/core/dist/assets/icons/action/ic_arrow_left_alt_24px.svg';
import { NexusIcon } from '@nexus/react';
import CustomTable from 'app/components/nds/customTable/customTable';
import {
    CONNECTION_TESTING_COLORS,
    CONTROL_TESTING_COLORS,
    RISK_EFFECTIVENESS_COLORS,
    RISK_LEVELS_COLORS,
} from 'constants/constants';
import { Chip } from 'app/components/ColorPalette';
import { useNavigate, useParams } from 'react-router-dom';
import { getControlByControlIdInfo, getRiskByControlID } from './redux/controlDetailsSlice.reducer';
import CountCircle from 'app/components/ColorPalette/CountCircle';
import LoaderComponent from 'app/components/nds/loader/loader';

function ControlDetails() {
    const { id } = useParams();

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getControlByControlIdInfo(id));
            dispatch(getRiskByControlID({ controlID: [id] }));
        }
    }, [id]);

    const { content: controlDetailDataResult } = useSelector(riskBycontrol);
    const totalCount = useSelector(riskBycontrol);
    const controlDetail = useSelector(controlBycontrolId);
    const isLoading = useSelector(getLoadingState);
    const [controlDetailData, setcontrolDetailData] = useState<any>([]);
    const [controlDetailTableData, setcontrolDetailTableData] = useState<any>([]);
    const riskName = controlDetail?.name;
    const [t] = useTranslation('lang');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const breadcrumbData = useSelector(getControlDetailBreadcrumb);

    useEffect(() => {
        updateControlDetailData();
    }, [controlDetail]);

    //sets data for control details.
    const updateControlDetailData = () => {
        setcontrolDetailData(controlDetail);
    };

    useEffect(() => {
        const data = controlDetailDataResult?.map((item: any) => {
            return {
                riskStatement: <span className='risk-description'> {item.statement}</span>,
                controls: (
                    <span
                        className='effectiveness'
                        style={{
                            backgroundColor: RISK_EFFECTIVENESS_COLORS[item.effectiveness].bgColor,
                            borderColor: RISK_EFFECTIVENESS_COLORS[item.effectiveness].borderColor,
                            color: RISK_EFFECTIVENESS_COLORS[item.effectiveness].textColor,
                        }}
                    >
                        <span className='label'>{t(`inventoryPage.${item.effectiveness}`)}</span>
                    </span>
                ),
                risk_id: (
                    <span onClick={() => navigate(`/risk-detail/${item.id}`)} className='risk-id'>
                        {item.id}
                    </span>
                ),
                inherentRisk: (
                    <>
                        <div className='nexus-flex-row'>
                            <Chip
                                className='risk-level-chip'
                                label={item?.inherentRiskRating}
                                color={RISK_LEVELS_COLORS[Math.floor(item?.inherentRiskRating)]}
                            />
                        </div>
                        <label>
                            <span className='risk-id'>{item.type}</span>
                        </label>
                    </>
                ),
            };
        });
        setcontrolDetailTableData(data);
    }, [controlDetailDataResult]);

    const controlDetailInfo = {
        'CONTROL ID': controlDetailData?.id,
        'DESCRIPTION': controlDetailData?.description,
        'CONTROL OWNERS': controlDetailData?.owners?.map((item: any) => item).join(','),
        'CONTROL TYPE': <span className='text-capitalize'>{controlDetailData?.type?.toLowerCase()}</span>,
        'ASSESSMENT UNIT': controlDetailData?.assessmentUnit,
    };

    const dataSources = {
        'SOURCE': controlDetailData?.dataSource?.name,
        'OWNER': controlDetailData?.dataSource?.owner,
        'REFRESH DATE': controlDetailData?.dataSource?.refreshDate,
        'CONNECTION STATUS': (
            <div>
                <span
                    className='connection-circle'
                    style={{
                        backgroundColor: CONNECTION_TESTING_COLORS[controlDetailData?.dataSource?.status]?.bgColor,
                    }}
                ></span>
                {controlDetailData?.dataSource?.status}
            </div>
        ),
        'CONTROL TEST DATE': '',
    };

    const prevPage = () => {
        navigate(-1);
    };

    const createAccordionContent = (title: string, isOpen: boolean, riskCount: number) => {
        return {
            info: '',
            isOpen: isOpen,
            title: (
                <div className='nexus-flex-row'>
                    {t(title)} <CountCircle RisksCount={riskCount} />
                </div>
            ),
        };
    };
    const accordionContentRisks = createAccordionContent('controlDetailPage.risks', true, totalCount?.totalElements);
    const accordionContentIssues = createAccordionContent('controlDetailPage.issues', false, 0);

    return (
        <div className='control-details'>
            {isLoading ? <LoaderComponent show={true}></LoaderComponent> : ''}
            <div className='breadcrums nexus-row nexus-mb-2'>
                <div className='arrow-position nexus-flex-row' onClick={() => prevPage()}>
                    <NexusIcon src={BackArrow} className='back-btn' />
                    <div className='label-div'>
                        <label className='label-back'>{t('riskDetailPage.Back')}</label>
                    </div>
                </div>

                <div
                    className='nexus-col-xs-2 nexus-col-md-2 nexus-col-lg-7 nexus-col-xl-8 nexus-mr-7'
                    style={{ marginRight: '10px' }}
                >
                    <BreadcrumbComponent separator='>' data={breadcrumbData} key={riskName} />
                </div>
            </div>

            <div className='risk-name nexus-flex-row'>
                <label className='risk-lables'>{controlDetailData?.name}</label>
            </div>

            <div className='nexus-flex-row'>
                <div className='nexus-flex-col first-sections'>
                    <label className='first-risk-section'>{t('controlDetailPage.testing')}</label>
                    <div className=''>
                        <span
                            className='effectiveness'
                            style={{
                                backgroundColor: CONTROL_TESTING_COLORS[controlDetailData?.testingStatus]?.bgColor,
                                borderColor: CONTROL_TESTING_COLORS[controlDetailData?.testingStatus]?.borderColor,
                                color: CONTROL_TESTING_COLORS[controlDetailData?.testingStatus]?.textColor,
                            }}
                        >
                            <span className='control-testing-status text-capitalize'>
                                {controlDetailData?.testingStatus?.toLowerCase()}
                            </span>
                        </span>
                    </div>
                </div>
            </div>

            <div className='second-section nexus-flex-row'>
                <div className='second-a-section'>
                    <CardComponent className={'second-a1-section'}>
                        <label className='second-card-title'>{t('controlDetailPage.controlDetails')}</label>
                        <CardDetails data={controlDetailInfo} />
                    </CardComponent>
                </div>
                <div className='second-b-section'>
                    <CardComponent className={'second-b1-section'}>
                        <label className='second-card-title'>{t('riskDetailPage.dataSource')}</label>
                        <CardDetails data={dataSources} parent={'dataSource'} />
                    </CardComponent>
                </div>
            </div>

            <CardComponent className={'card-a1-section'}>
                <div className='accordion-section'>
                    <AccordionComponent
                        accordionContent={accordionContentRisks}
                        size='md'
                        key={totalCount?.totalElements}
                    >
                        <div>
                            <CustomTable
                                columnsDef={[
                                    {
                                        field: 'risk_id',
                                        id: '0',
                                        isSortable: false,
                                        label: <div>{t('controlDetailPage.riskID')}</div>,
                                        minWidth: 150,
                                    },
                                    {
                                        field: 'inherentRisk',
                                        id: '1',
                                        isSortable: false,
                                        label: <div>{t('controlDetailPage.inherentRisk')}</div>,
                                        minWidth: 150,
                                    },
                                    {
                                        field: 'controls',
                                        id: '2',
                                        isSortable: false,
                                        label: <div>{t('controlDetailPage.controls')}</div>,
                                        minWidth: 150,
                                    },
                                    {
                                        field: 'riskStatement',
                                        id: '3',
                                        isSortable: false,
                                        label: <div>{t('controlDetailPage.riskStatement')}</div>,
                                        minWidth: 600,
                                    },
                                ]}
                                data={controlDetailTableData || []}
                                className='inventory-table'
                            />
                        </div>
                    </AccordionComponent>
                </div>
                <div className='accordion-section'>
                    <AccordionComponent
                        accordionContent={accordionContentIssues}
                        size='md'
                        key={totalCount?.totalElements}
                    >
                        <div>
                            <CustomTable
                                columnsDef={[
                                    {
                                        field: 'risk_id',
                                        id: '0',
                                        isSortable: false,
                                        label: <div>{t('controlDetailPage.riskID')}</div>,
                                        minWidth: 150,
                                    },
                                    {
                                        field: 'inherentRisk',
                                        id: '1',
                                        isSortable: false,
                                        label: <div>{t('controlDetailPage.inherentRisk')}</div>,
                                        minWidth: 150,
                                    },
                                    {
                                        field: 'controls',
                                        id: '2',
                                        isSortable: false,
                                        label: <div>{t('controlDetailPage.controls')}</div>,
                                        minWidth: 150,
                                    },
                                    {
                                        field: 'riskStatement',
                                        id: '3',
                                        isSortable: false,
                                        label: <div>{t('controlDetailPage.riskStatement')}</div>,
                                        minWidth: 600,
                                    },
                                ]}
                                data={[]}
                                className='inventory-table'
                            />
                        </div>
                    </AccordionComponent>
                </div>
            </CardComponent>
        </div>
    );
}

export default ControlDetails;
