import { ReducerCreators } from '@reduxjs/toolkit';
import { SearchApiFactory as BLsearchApiFactory, FollowedEnum } from 'app/api/dra-bl-service';
import { SearchApiFactory } from 'app/api/dra-rdh-service';
import { blApiConfig, rdhApiConfig } from 'constants/apiConstants';
import { HOME_URL, INVENTORY_URL } from 'constants/constants';
import { createAppSlice } from 'store/sliceHelper';
import { removeMessage } from 'utils/actionUtils';

export const INITIAL_STATE_INVENTORY: any = {
    breadcrumbData: [
        { isEnabled: true, name: 'Dashboard', path: HOME_URL },
        { isEnabled: false, name: 'Inventory', path: INVENTORY_URL },
    ],
    filters: {
        Controls: {},
        Processes: {},
        Risks: {},
    },
    messages: [],
    risksData: {},
};
type inventoryState = Readonly<typeof INITIAL_STATE_INVENTORY>;

const searchApiFactoryDra = SearchApiFactory(rdhApiConfig);
const searchApiFactory = BLsearchApiFactory(blApiConfig);

export const inventorySliceReducer = createAppSlice({
    initialState: INITIAL_STATE_INVENTORY as inventoryState,
    name: 'inventoryData',
    reducers: (create: ReducerCreators<any>) => {
        return {
            addFilter: create.reducer<any>((state, action: any) => {
                state.filters[action.payload.tab][action.payload.filter] = [
                    ...(state.filters[action.payload.tab][action.payload.filter] || []),
                    { id: action.payload.id, label: action?.payload?.label, value: action.payload.value },
                ];
            }),
            addMultipleFilters: create.reducer<any>((state, action: any) => {
                state.filters[action.payload.tab][action.payload.filter] = [
                    ...(state.filters[action.payload.tab][action.payload.filter] || []),
                    ...action.payload.values.map(({ value, id, label }: any) => ({ id, label, value })),
                ];
            }),
            removeFilter: create.reducer<any>((state, action: any) => {
                state.filters[action.payload.tab][action.payload.filter] =
                    state.filters[action.payload.tab][action.payload.filter]?.filter(
                        (item: any) => item.id !== action.payload.id,
                    ) || [];
            }),

            removeMultipleFilters: create.reducer<any>((state, action: any) => {
                state.filters[action.payload.tab][action.payload.filter] =
                    state.filters[action.payload.tab][action.payload.filter]?.filter(
                        (item: any) => !action.payload.values.map((item: any) => item.id).includes(item.id),
                    ) || [];
            }),
            resetFilters: create.reducer<any>((state) => {
                state.filters = {
                    Controls: {},
                    Processes: {},
                    Risks: {},
                };
            }),
            searchProcess: create.asyncThunk(
                async (
                    {
                        searchTerm,
                        sectorIds,
                        businessUnitIds,
                        regionIds,
                        categoryIds,
                        page,
                        size,
                        sort,
                    }: {
                        page: number;
                        size: number;
                        sort: string[];
                        searchTerm: string;
                        sectorIds: string[];
                        businessUnitIds: string[];
                        regionIds: string[];
                        categoryIds: string[];
                        controlIds: string[];
                    },
                    { rejectWithValue },
                ) => {
                    try {
                        const response: { data: any } = await searchApiFactoryDra.searchProcesses(
                            sectorIds,
                            businessUnitIds,
                            regionIds,
                            categoryIds,
                            searchTerm,
                            page,
                            size,
                            sort,
                        );
                        return response;
                    } catch (error) {
                        return rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.processData = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'searchProcess');
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),
            searchRisk: create.asyncThunk(
                async (
                    {
                        page,
                        size,
                        sort,
                        searchTerm,
                        sectorIds,
                        businessUnitIds,
                        regionIds,
                        categoryIds,
                        controlIds,
                        followed,
                        effectiveness,
                    }: {
                        page: number;
                        size: number;
                        sort: string[];
                        searchTerm: string;
                        sectorIds: string[];
                        businessUnitIds: string[];
                        regionIds: string[];
                        categoryIds: string[];
                        controlIds: string[];
                        followed: FollowedEnum[];
                        effectiveness: any[];
                    },
                    { rejectWithValue },
                ) => {
                    try {
                        console.log(effectiveness);
                        const response: { data: any } = await searchApiFactory.searchRisks(
                            sectorIds,
                            businessUnitIds,
                            regionIds,
                            categoryIds,
                            controlIds,
                            followed,
                            effectiveness,
                            searchTerm,
                            page,
                            size,
                            sort,
                        );
                        return response;
                    } catch (error) {
                        return rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.risksData = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'searchRisk');
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),

            searchControls: create.asyncThunk(
                async (
                    {
                        page,
                        size,
                        sort,
                        searchTerm,
                        sectorIds,
                        businessUnitIds,
                        regionIds,
                        categoryIds,
                        riskId,
                    }: {
                        page: number;
                        size: number;
                        sort: string[];
                        searchTerm: string;
                        sectorIds: string[];
                        businessUnitIds: string[];
                        regionIds: string[];
                        categoryIds: string[];
                        riskId: string[];
                    },
                    { rejectWithValue },
                ) => {
                    try {
                        const response: { data: any } = await searchApiFactoryDra.searchControls(
                            sectorIds,
                            businessUnitIds,
                            regionIds,
                            categoryIds,
                            riskId,
                            searchTerm,
                            page,
                            size,
                            sort,
                        );
                        return response;
                    } catch (error) {
                        return rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.controlsData = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'searchRisk');
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),

            updateBreadcrumb: create.reducer<any>((state, action: any) => {
                state.breadcrumbData = action.payload;
            }),
        };
    },
});

export const {
    searchProcess,
    searchRisk,
    searchControls,
    updateBreadcrumb,
    removeFilter,
    addFilter,
    addMultipleFilters,
    removeMultipleFilters,
    resetFilters,
} = inventorySliceReducer.actions;
export default inventorySliceReducer.reducer;
