/* eslint-disable sort-keys */
//import { removeMessage } from 'utils/actionUtils';
import { createAppSlice } from 'store/sliceHelper';
import { RisksApiFactory, RiskPageDTO, ControlsApiFactory } from 'app/api/dra-rdh-service';
import { ReducerCreators } from '@reduxjs/toolkit';
import { rdhApiConfig, blApiConfig } from 'constants/apiConstants';

const risksApiFactory = RisksApiFactory(blApiConfig);
const controlsApiFactory = ControlsApiFactory(rdhApiConfig);

type GetRiskByIdParams = {
    controlID: Array<string>;
};
export const INITIAL_STATE_HOME: any = {
    RiskHistoryLevels: {},
    breadcrumbData: [{ isEnabled: true, name: 'Dashboard', path: '/home' }],
    getControlbyIds: {},
    getcontrolBycontrolIdInfo: {},
};
type homeState = Readonly<typeof INITIAL_STATE_HOME>;
export const homeSliceReducer = createAppSlice({
    initialState: INITIAL_STATE_HOME as homeState,
    name: 'controlDetailData',
    reducers: (create: ReducerCreators<any>) => {
        return {
            getControlByControlIdInfo: create.asyncThunk(
                async (controlId: string, { rejectWithValue }) => {
                    try {
                        const response = await controlsApiFactory.getControl(controlId);
                        return response;
                    } catch (error) {
                        rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.controlBycontrolId = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),

            getRiskByControlID: create.asyncThunk(
                async ({ controlID }: GetRiskByIdParams, { rejectWithValue }) => {
                    try {
                        const response: { data: RiskPageDTO } = await risksApiFactory.getRisks(
                            [''],
                            [''],
                            [''],
                            [''],
                            controlID,
                            [''],
                        );
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.riskBycontrolID = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                    },
                    rejected: (state) => {
                        console.log('rejected the api');
                        state.isLoading = false;
                    },
                },
            ),
            updateBreadcrumb: create.reducer<any>((state, action: any) => {
                state.breadcrumbData = action.payload;
            }),
        };
    },
});
export const { getControlByControlIdInfo, getRiskByControlID, updateBreadcrumb } = homeSliceReducer.actions;
export default homeSliceReducer.reducer;
