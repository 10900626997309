import { NexusIcon } from '@nexus/react';
import ChipArrow from 'app/components/ColorPalette/chipArrow';
import React from 'react';
import rightArrow from '@nexus/core/dist/assets/icons/action/ic_chevron_right_24px.svg';
import './tabList.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateBreadcrumb } from 'app/views/risk_details/redux/riskDetailSlice.reducer';
import { HOME_URL } from 'constants/constants';

function TabList({ Data, Cardcolor, chipColour, key, type, isLob }: any) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const getRegionByRegionId = (item: { id: string; name: string }) => {
        switch (type) {
            case 'Risks':
                dispatch(
                    updateBreadcrumb([
                        { isEnabled: true, name: 'Dashboard', path: HOME_URL },
                        { isEnabled: true, name: item.name },
                    ]),
                );
                navigate(`/risk-detail/${item.id}`);
                break;

            case 'Processes':
                dispatch(
                    updateBreadcrumb([
                        { isEnabled: true, name: 'Dashboard', path: HOME_URL },
                        { isEnabled: true, name: item.name },
                    ]),
                );
                navigate(`/process-detail/${item.id}`);
                break;

            case 'Controls':
                dispatch(
                    updateBreadcrumb([
                        { isEnabled: true, name: 'Dashboard', path: HOME_URL },
                        { isEnabled: true, name: 'Controls' },
                    ]),
                );
                navigate(`/control-detail/${item.id}`);
                break;
        }
    };
    return (
        <div className={`third-a-section nexus-flex-row ${type.toLowerCase()}`} key={key || type}>
            <div className='nexus-flex-col third-b-section'>
                <div className='third-b1-section'>
                    {Data.map((item: any) => (
                        <div
                            key={type + item.id}
                            className={`third-drawer-section-c `}
                            style={{
                                backgroundColor:
                                    type === 'Controls' ? Cardcolor.bgColor : Cardcolor[Math.round(item.rating)],
                            }}
                            onClick={() => getRegionByRegionId(item)}
                        >
                            <div className='third-label-id'>{item.id}</div>
                            <div className=' third-label-name  nexus-flex-row'>
                                <div>{item.name}</div>
                                <div className=' position_chip nexus-flex-row'>
                                    <ChipArrow
                                        text={item.rating}
                                        bgColor={
                                            type === 'Controls'
                                                ? chipColour[item.rating].bgColor
                                                : chipColour[Math.round(item.rating)]
                                        }
                                        color={type === 'Controls' ? chipColour[item.rating].borderColor : 'white'}
                                        chipType={type}
                                        disableArrow={type === 'Controls' || type === 'Processes' ? true : false}
                                    />

                                    <NexusIcon src={rightArrow} onClick={() => getRegionByRegionId(item.id)} />
                                </div>
                            </div>

                            {isLob ? (
                                <div className='third-lable-sector'>{item.country}</div>
                            ) : (
                                <div className='third-lable-sector'>
                                    {item.sectorName}/{item.businessUnitName}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default TabList;
