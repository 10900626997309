import React, { useEffect } from 'react';
import favStarIcon from '@nexus/core/dist/assets/icons/action/ic_grade_24px.svg';
import favStarBorderIcon from '@nexus/core/dist/assets/icons/action/ic_star_half_24px.svg';
import IconComponent from 'app/components/nds/icon/icon';
import { blRisksApiFactory } from 'app/views/risk_details/redux/riskDetailSlice.reducer';
// import { NexusTooltip, NexusTooltipContent, NexusTooltipTrigger } from '@nexus/react';
import './favComponent.scss';

interface Props {
    data: any;
}
function FavComponent({ data }: Props) {
    const [selected, setSelected] = React.useState(data.followed || false);
    const [hover, setHover] = React.useState(false);
    useEffect(() => {
        setSelected(data.followed || false);
    }, [data]);

    return (
        <div
            className='fav-component'
            onMouseLeave={() => setHover(false)}
            onMouseEnter={() => setHover(true)}
            onClick={() => {
                if (!selected) {
                    blRisksApiFactory.setRiskAsFollow(data.id);
                } else {
                    blRisksApiFactory.setRiskAsUnfollow(data.id);
                }
                setSelected(!selected);
            }}
        >
            {hover && <div className='fav-tooltip'>{selected ? 'Unfollow' : 'Follow'}</div>}
            <div>
                <IconComponent
                    key={data.id + '-' + data.followed ? 'followed' : 'unfollowed'}
                    src={selected ? favStarBorderIcon : favStarIcon}
                />
            </div>
        </div>
    );
}

export default FavComponent;
