/* eslint-disable sort-keys */
import React, { useEffect, useRef, useState } from 'react';
import MapComponent from 'app/components/map';
import CardComponent from 'app/components/nds/card/card';
import './home.scss';
import CustomTable from 'app/components/nds/customTable/customTable';
import { DonutChart } from 'app/components/nds/donutChart';
import { Chip, ColorRange } from 'app/components/ColorPalette';
import { useDispatch, useSelector } from 'react-redux';
import {
    allRiskCategories,
    allRiskLevels,
    allRiskRegion,
    allcatergories,
    controlEffectivenessCountSelector,
    getAllControlsSelector,
    getAllFilteredControlSelector,
    getAllFilteredProcessesSelector,
    getAllProcessesSelector,
    getAllRiskSector,
    getLoadingState,
    getRigionByID,
    getRisksId,
} from './redux/homeSelectors';
import { useTranslation } from 'react-i18next';
import LineChart from 'app/components/nds/lineChart/linechart';
import ChipArrow from 'app/components/ColorPalette/chipArrow';
import DrawerComponent from 'app/components/drawerComponent';
import TabList from './summeryDrawer/tabList';
import TabsComponent from 'app/components/nds/tabs/tabs';
import { Link, useNavigate } from 'react-router-dom';
import IconComponent from 'app/components/nds/icon/icon';
import arrow from '@nexus/core/dist/assets/icons/action/ic_arrow_right_alt_24px.svg';
import CountCircle from 'app/components/ColorPalette/CountCircle';
import {
    CARD_RISK_LEVEL_COLORS,
    LINE_CHART_COLORS,
    CONTROL_EFFECTIVENESS_COLORS,
    RISK_LEVELS_COLORS,
    EFFECTIVENESS_COLOURS,
    defaultColorScheme,
    HOME_URL,
    NO_DATA_COLOR,
} from 'constants/constants';
import {
    getAllCatagoryInfo,
    getAllRiskSectorsInfo,
    getControlEffectivenessCount,
    getProcessesData,
    getRiskByRegionId,
    getRiskById,
    getRiskCatagoryInfo,
    getRiskTypeByRegionInfo,
    getRiskTypesByLevel,
    getAllProcessesByFilter,
    getAllControlsByFilter,
    getControlsData,
} from './redux/homeSlice.reducer';
import ButtonComponent from 'app/components/nds/button/button';
import { addFilter, resetFilters, updateBreadcrumb } from '../inventory/redux/inventorySlice.reducer';
import LoaderComponent from 'app/components/nds/loader/loader';

function Home() {
    const navigate = useNavigate();
    const lineChartContainerRef = useRef<any>(null);
    const [t] = useTranslation('lang');
    const dispatch = useDispatch();
    const [category, setCategory] = React.useState<{ value: string; id: string }>({ value: 'All', id: '' });
    const [country, setcountry] = React.useState<{ value: string; id: string }>({ value: 'All', id: '' });
    const [businessUnit, setbusinessUnit] = React.useState<{ value: string; id: string }>({ value: 'All', id: '' });
    const { content: riskContent } = useSelector(getRisksId);
    const { content: processContent } = useSelector(getAllFilteredProcessesSelector);
    const { content: controlContent } = useSelector(getAllFilteredControlSelector);
    const [riskTablistData, setRiskTablistData] = useState([]);
    const [processTablistData, setprocessTablistData] = useState([]);
    const [controlsTablistData, setControlsTablistData] = useState([]);
    const [riskType, setRiskType] = React.useState<string>('Residual');
    const [mapData, setMapData] = React.useState<any>([]);
    const [riskDounutData, setRiskDounutData] = React.useState<any>({ data: [], count: 0 });
    const [controlEffectivenessData, setControlEffectivenessData] = React.useState<any>({ data: [], count: 0 });
    const [riskLineChart, setRiskLineChart] = React.useState<any>({ dates: [], data: [] });
    const [openRiskSummary, setOpenRiskSummary] = React.useState<boolean | undefined>();
    const [openLobSummary, setopenLobSummary] = React.useState<boolean | undefined>();
    const [businessData, setBusinessData] = React.useState<any>({});
    const [tabName, setTabName] = React.useState<any>('Risks');
    const graphData = useSelector(allRiskCategories);
    const isLoading = useSelector(getLoadingState);
    const riskRegion = useSelector(allRiskRegion);
    const allRiskSector = useSelector(getAllRiskSector);
    const riskTypesByLevel = useSelector(allRiskLevels);
    const controlEffectivenessCount = useSelector(controlEffectivenessCountSelector);
    let allcategories = useSelector(allcatergories);
    allcategories = [{ name: 'All', id: 'All' }, ...allcategories];
    const { totalElements: processesCount } = useSelector(getAllProcessesSelector);
    const { totalElements: RisksCount } = useSelector(getRisksId);
    const { totalElements: controlsCount } = useSelector(getAllControlsSelector);
    const [lobFlag, setLobflag] = useState(false);
    const tabOption = [
        {
            isActive: false,
            tabName: 'Processes',
            title: (
                <div className='nexus-flex-row'>
                    {t('homePage.Process')} <CountCircle RisksCount={processesCount} />
                </div>
            ),
            value: (
                <TabList
                    key={(processTablistData.length ? 'risk-tab' : '') + riskType}
                    Data={processTablistData}
                    Cardcolor={CARD_RISK_LEVEL_COLORS}
                    chipColour={RISK_LEVELS_COLORS}
                    type={tabName}
                    isLob={lobFlag}
                ></TabList>
            ),
        },
        {
            isActive: true,
            tabName: 'Risks',
            title: (
                <div className='nexus-flex-row'>
                    {t('homePage.Risks')} <CountCircle RisksCount={RisksCount} />
                </div>
            ),
            value: (
                <TabList
                    key={(riskTablistData.length ? 'risk-tab' : '') + riskType}
                    Data={riskTablistData}
                    Cardcolor={CARD_RISK_LEVEL_COLORS}
                    chipColour={RISK_LEVELS_COLORS}
                    type={tabName}
                    isLob={lobFlag}
                ></TabList>
            ),
        },
        {
            isActive: false,
            tabName: 'Controls',
            title: (
                <div className='nexus-flex-row'>
                    {t('homePage.Controls')} <CountCircle RisksCount={controlsCount} />
                </div>
            ),
            value: (
                <TabList
                    key={(controlsTablistData.length ? 'risk-tab' : '') + riskType}
                    Data={controlsTablistData}
                    Cardcolor={defaultColorScheme}
                    chipColour={EFFECTIVENESS_COLOURS}
                    type={tabName}
                    isLob={lobFlag}
                ></TabList>
            ),
        },
    ];
    const riskRegionData = riskRegion.map(
        (record: {
            region: { id: any; name: any; code: any };
            residualRiskLevel: any;
            inherentRiskLevel: any;
            residualPrevRiskLevel: any;
            inherentPrevRiskLevel: any;
        }) => {
            let inherentTrend = '';
            let residualTrend = '';
            if (record.inherentRiskLevel && record.inherentPrevRiskLevel) {
                inherentTrend = record.inherentRiskLevel > record.inherentPrevRiskLevel ? 'UP' : 'DOWN';
            }
            if (record.residualRiskLevel && record.residualPrevRiskLevel) {
                residualTrend = record.residualRiskLevel > record.residualPrevRiskLevel ? 'UP' : 'DOWN';
            }
            return {
                code: record.region.code,
                inherentRiskLevel: record.inherentRiskLevel,
                inherentTrend,
                name: record.region.name,
                regionId: record.region.id,
                residualRiskLevel: record.residualRiskLevel,
                residualTrend,
            };
        },
    );
    const allRiskSectorData = allRiskSector.map(
        (record: {
            businessUnitId: any;
            businessUnitName: any;
            inherentRiskLevel: any;
            inherentPrevRiskLevel: any;
            residualRiskLevel: any;
            residualPrevRiskLevel: any;
            sectorName: any;
            sectorId: any;
        }) => {
            let inherentTrend = '';
            let residualTrend = '';
            if (record.inherentRiskLevel && record.inherentPrevRiskLevel) {
                inherentTrend = record.inherentRiskLevel > record.inherentPrevRiskLevel ? 'UP' : 'DOWN';
            }
            if (record.residualRiskLevel && record.residualPrevRiskLevel) {
                residualTrend = record.residualRiskLevel > record.residualPrevRiskLevel ? 'UP' : 'DOWN';
            }
            return {
                businessUnitId: record.businessUnitId,
                businessUnitName: record.businessUnitName,
                inherentPrevRiskLevel: record.inherentPrevRiskLevel,
                inherentRiskLevel: record.inherentRiskLevel,
                inherentTrend,
                residualPrevRiskLevel: record.residualPrevRiskLevel,
                residualRiskLevel: record.residualRiskLevel,
                residualTrend,
                sectorId: record.sectorId,
                sectorName: record.sectorName,
            };
        },
    );
    const getRiskBusinessId = (e: any) => {
        setbusinessUnit({ value: e.businessUnitName, id: e.businessUnitId });
        setopenLobSummary(!openLobSummary);
        setBusinessData(e);
        dispatch(getRiskById({ businessUnitIds: [e.businessUnitId], regionIds: [''] }));
        dispatch(getAllProcessesByFilter({ businessUnitIds: [e.businessUnitId], regionIds: [''] }));
        dispatch(getAllControlsByFilter({ businessUnitIds: [e.businessUnitId], regionIds: [''] }));
        setLobflag(true);
    };
    const regionID = useSelector(getRigionByID);

    const regionIData = (({
        residualRiskLevel = '',
        residualPrevRiskLevel = '',
        inherentRiskLevel = '',
        inherentPrevRiskLevel = '',
        region: { name = '' } = {},
    }) => {
        let inherentTrend = '';
        let residualTrend = '';
        if (inherentRiskLevel && inherentPrevRiskLevel) {
            inherentTrend = inherentRiskLevel > inherentPrevRiskLevel ? 'UP' : 'DOWN';
        }
        if (residualRiskLevel && residualPrevRiskLevel) {
            residualTrend = residualRiskLevel > residualPrevRiskLevel ? 'UP' : 'DOWN';
        }
        return {
            inherentPrevRiskLevel: inherentPrevRiskLevel,
            inherentRiskLevel: inherentRiskLevel,
            inherentTrend,
            name,
            residualPrevRiskLevel: residualPrevRiskLevel,
            residualRiskLevel: residualRiskLevel,
            residualTrend,
        };
    })(regionID || {});

    useEffect(() => {
        const transformedRiskData =
            riskContent?.map(
                (item: {
                    sector: any;
                    businessUnit: any;
                    id: any;
                    name: any;
                    residualRiskRating: any;
                    inherentRiskRating: any;
                    region: any;
                }) => {
                    return {
                        businessUnitName: item.businessUnit.name,
                        id: item.id,
                        name: item.name,
                        rating: riskType === 'Residual' ? item.residualRiskRating : item.inherentRiskRating,
                        sectorName: item.sector.name,
                        country: item?.region?.name,
                    };
                },
            ) || [];
        setRiskTablistData(transformedRiskData);
    }, [riskContent]);

    useEffect(() => {
        const transformedProcessData =
            processContent?.map(
                (item: { sector: any; businessUnit: any; id: any; name: any; rating: any; region: any }) => {
                    return {
                        businessUnitName: item.businessUnit.name,
                        id: item.id,
                        name: item.name,
                        rating: item.rating,
                        sectorName: item.sector.name,
                        country: item?.region?.name,
                    };
                },
            ) || [];
        setprocessTablistData(transformedProcessData);
    }, [processContent]);

    useEffect(() => {
        const transformedControlData =
            controlContent?.map(
                (item: { sector: any; businessUnit: any; id: any; name: any; effectiveness: any; region: any }) => {
                    return {
                        businessUnitName: item.businessUnit.name,
                        id: item.id,
                        name: item.name,
                        rating: item.effectiveness,
                        sectorName: item.sector.name,
                        country: item?.region?.name,
                    };
                },
            ) || [];
        setControlsTablistData(transformedControlData);
    }, [controlContent]);

    const getrRiskByRegionId = (e: any) => {
        setLobflag(false);
        setcountry({ value: e.name, id: e.regionId });
        setOpenRiskSummary(!openRiskSummary);
        dispatch(getRiskByRegionId({ regionId: e.regionId }));
        dispatch(getRiskById({ businessUnitIds: [''], regionIds: [e.regionId] }));
        dispatch(getAllProcessesByFilter({ businessUnitIds: [''], regionIds: [e.regionId] }));
        dispatch(getAllControlsByFilter({ businessUnitIds: [''], regionIds: [e.regionId] }));
    };
    const colDetails = [
        {
            cellClass: 'region-cell',
            field: 'name',
            headerClass: 'region-header',
            id: '1',
            isSortable: false,
            label: <div>{t('homePage.Region')}</div>,
            minWidth: 165,
        },
        {
            cellClass: 'residual-risk-cell',
            field: riskType === 'Residual' ? 'residualRiskLevel' : 'inherentRiskLevel',
            headerClass: 'residual-risk-header',
            id: '1',
            isSortable: false,
            label: <div>{t(riskType === 'Residual' ? 'homePage.ResidualRisk' : 'homePage.InherentRisk')}</div>,
            minWidth: 150,
        },
    ];
    const colDetailLOB = [
        {
            cellClass: 'region-cell',
            field: 'businessUnitName',
            headerClass: 'businessUnitName',
            id: '1',
            isSortable: false,
            label: <div>{t('homePage.LOB')}</div>,
            minWidth: 250,
        },
        {
            cellClass: 'Sector-cell',
            field: 'sectorName',
            headerClass: 'Sector-header',
            id: '1',
            isSortable: false,
            label: <div>{t('homePage.Sector')}</div>,
            minWidth: 165,
        },
        {
            cellClass: 'residual-risk-cell',
            field: riskType === 'Residual' ? 'residualRiskLevel' : 'inherentRiskLevel',
            headerClass: 'residual-risk-header',
            id: '1',
            isSortable: false,
            label: <div>{t(riskType === 'Residual' ? 'homePage.ResidualRisk' : 'homePage.InherentRisk')}</div>,
            minWidth: 150,
        },
    ];
    const riskTypes = [{ name: 'Residual' }, { name: 'Inherent' }];
    useEffect(() => {
        dispatch(getAllCatagoryInfo());
        dispatch(getControlEffectivenessCount());
        dispatch(getProcessesData());
        dispatch(getControlsData());
    }, []);
    useEffect(() => {
        dispatch(getRiskTypeByRegionInfo(category.value !== 'All' ? { categoryId: category.id } : {}));
        dispatch(getRiskCatagoryInfo(category.value !== 'All' ? { categoryId: category.id } : {}));
        dispatch(getRiskTypesByLevel(category.value !== 'All' ? { categoryId: category.id } : {}));
        dispatch(getAllRiskSectorsInfo(category.value !== 'All' ? { categoryId: category.id } : {}));
    }, [category]);

    // map date update
    useEffect(() => {
        const countryData = riskRegionData.map((country: any) => {
            const color =
                RISK_LEVELS_COLORS[riskType === 'Residual' ? country?.residualRiskLevel : country?.inherentRiskLevel] ||
                NO_DATA_COLOR;
            return {
                ...country,
                color,
            };
        });
        setMapData(countryData);
    }, [riskRegion, riskType]);

    // pie chart data update
    useEffect(() => {
        const key = riskType === 'Residual' ? 'residualAmount' : 'inherentAmount';
        let riskCount = 0;
        let riskChartColor: any = [];

        const riskData = riskTypesByLevel?.content?.map((data: any) => {
            riskCount += data[key];
            riskChartColor.push(RISK_LEVELS_COLORS[data.rightRange]);
            return { color: RISK_LEVELS_COLORS[data.rightRange], name: key, value: data[key] };
        });

        setRiskDounutData({ count: riskCount, data: riskData });

        let controlCount = 0;
        let controlChartColor: any = [];
        const controlData = Object.keys(controlEffectivenessCount).map((effectiveness) => {
            controlCount += controlEffectivenessCount[effectiveness];
            controlChartColor.push(CONTROL_EFFECTIVENESS_COLORS[effectiveness]);
            return {
                color: CONTROL_EFFECTIVENESS_COLORS[effectiveness],
                name: effectiveness,
                value: controlEffectivenessCount[effectiveness],
            };
        });
        setControlEffectivenessData({ count: controlCount, data: controlData });
    }, [riskTypesByLevel, controlEffectivenessCount, graphData, riskType]);

    //line chart data update
    useEffect(() => {
        const lineChartKey = riskType === 'Residual' ? 'residualRiskRating' : 'inherentRiskRating';
        let year = '';
        const data = graphData.reduce((acc: any, item: any) => {
            year = item.year;
            acc[item.category.name] = [
                ...(acc[item.category.name] || []),
                {
                    category: item.category.name,
                    date: item.year + '-' + item.month + '-' + 1,
                    inherentRiskRating: item.inherentRiskRating,
                    month: item.month,
                    residualRiskRating: item.residualRiskRating,
                },
            ];
            return acc;
        }, {});
        const updatedData = Object.keys(data).reduce((acc: any, key: any) => {
            if (category.value !== 'All' && key !== category.value) {
                return acc;
            }
            let temp: any = {
                color: LINE_CHART_COLORS[key],
                data: [],
                name: key,
            };
            for (let i = 0; i < 12; i++) {
                const monthData = data[key].find((item: any) => item.month === i);
                if (monthData) {
                    temp.data.push({ x: monthData.date, y: monthData[lineChartKey] });
                }
            }
            acc.push(temp);
            return acc;
        }, []);
        setRiskLineChart({
            data: updatedData,
            range: [
                new Date(Date.UTC(parseInt(year), 0, 0, 18, 30, 0)),
                new Date(Date.UTC(parseInt(year), 11, 31, 0, 0, 0)),
            ],
        });
    }, [riskType, category, graphData]);
    const rowsTransformation = (rows: any) => {
        return rows.map((row: any) => {
            return {
                ...row,
                [riskType === 'Residual' ? 'residualRiskLevel' : 'inherentRiskLevel']: (
                    <ChipArrow
                        text={riskType === 'Residual' ? row.residualRiskLevel : row.inherentRiskLevel}
                        bgColor={
                            RISK_LEVELS_COLORS[riskType === 'Residual' ? row.residualRiskLevel : row.inherentRiskLevel]
                        }
                        color={
                            riskType === 'Residual'
                                ? row.residualRiskLevel === 5
                                    ? 'white'
                                    : 'black'
                                : row.inherentRiskLevel === 5
                                ? 'white'
                                : 'black'
                        }
                        arrowDirection={riskType === 'Residual' ? row.residualTrend : row.inherentTrend}
                    />
                ),
            };
        });
    };
    const rowsTransformationLOB = (rows: any) => {
        return rows.map((row: any) => {
            return {
                ...row,
                [riskType === 'Residual' ? 'residualRiskLevel' : 'inherentRiskLevel']: (
                    <ChipArrow
                        text={riskType === 'Residual' ? row.residualRiskLevel : row.inherentRiskLevel}
                        bgColor={
                            RISK_LEVELS_COLORS[riskType === 'Residual' ? row.residualRiskLevel : row.inherentRiskLevel]
                        }
                        color={
                            riskType === 'Residual'
                                ? row.residualRiskLevel === 5
                                    ? 'white'
                                    : 'black'
                                : row.inherentRiskLevel === 5
                                ? 'white'
                                : 'black'
                        }
                        arrowDirection={riskType === 'Residual' ? row.residualTrend : row.inherentTrend}
                    />
                ),
            };
        });
    };

    useEffect(() => {
        dispatch(resetFilters({}));
    }, []);

    const setRiskFilterData = () => {
        addCategoryFilter(tabName);
        dispatch(
            addFilter({
                filter: 'regions',
                id: country.id,
                tab: tabName,
                value: country.value,
            }),
        );

        switch (tabName) {
            case 'Risks':
                navigate('/inventory/risks');
                dispatch(
                    updateBreadcrumb([
                        { isEnabled: true, name: 'Dashboard', path: HOME_URL },
                        { isEnabled: false, name: 'Inventory' },
                    ]),
                );

                break;

            case 'Processes':
                navigate('/inventory/processes');
                dispatch(
                    updateBreadcrumb([
                        { isEnabled: true, name: 'Dashboard', path: HOME_URL },
                        { isEnabled: false, name: 'Inventory' },
                    ]),
                );
                break;

            case 'Controls':
                navigate('/inventory/controls');
                dispatch(
                    updateBreadcrumb([
                        { isEnabled: true, name: 'Dashboard', path: HOME_URL },
                        { isEnabled: false, name: 'Inventory' },
                    ]),
                );
                break;
        }
    };
    const setFilterDataLOB = () => {
        addCategoryFilter(tabName);
        dispatch(
            addFilter({
                filter: 'businessUnit',
                id: businessUnit.id,
                tab: tabName,
                value: businessUnit.value,
            }),
        );
        switch (tabName) {
            case 'Risks':
                navigate('/inventory/risks');
                dispatch(
                    updateBreadcrumb([
                        { isEnabled: true, name: 'Dashboard', path: HOME_URL },
                        { isEnabled: false, name: 'Inventory' },
                    ]),
                );
                break;

            case 'Processes':
                navigate('/inventory/processes');
                dispatch(
                    updateBreadcrumb([
                        { isEnabled: true, name: 'Dashboard', path: HOME_URL },
                        { isEnabled: false, name: 'Inventory' },
                    ]),
                );
                break;

            case 'Controls':
                navigate('/inventory/controls');
                dispatch(
                    updateBreadcrumb([
                        { isEnabled: true, name: 'Dashboard', path: HOME_URL },
                        { isEnabled: false, name: 'Inventory' },
                    ]),
                );
                break;
        }
    };
    const addCategoryFilter = (tab: string) => {
        category.value !== 'All' &&
            dispatch(
                addFilter({
                    filter: 'categories',
                    id: category.id,
                    tab: tab,
                    value: category.value,
                }),
            );
    };

    return (
        <div className='home'>
            <div className='options-container nexus-flex-row'>
                <div className='nexus-flex-col categories-container'>
                    <label className='options-label'>{t('homePage.category')}</label>
                    <div className='nexus-flex-row categories'>
                        {allcategories.map(({ name, id }: any) => {
                            return (
                                <div
                                    tabIndex={0}
                                    key={id}
                                    className={`nexus-flex-row category ${name === category.value ? 'active' : ''} `}
                                    onClick={() => {
                                        setCategory({ value: name, id });
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.code === 'Enter') {
                                            setCategory({ value: name, id });
                                        }
                                    }}
                                >
                                    {name}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className='nexus-flex-col risk-types-container'>
                    <label className='options-label'>{t('homePage.riskType')}</label>
                    <div className='nexus-flex-row risk-types '>
                        {riskTypes.map((data) => {
                            return (
                                <div
                                    tabIndex={0}
                                    key={data.name}
                                    className={`nexus-flex-row risk-type ${data.name === riskType ? 'active' : ''} `}
                                    onClick={() => setRiskType(data.name)}
                                    onKeyDown={(e) => {
                                        if (e.code === 'Enter') {
                                            setRiskType(data.name);
                                        }
                                    }}
                                >
                                    {data.name}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className='first-section'>
                <CardComponent className={'risk-region-card'}>
                    <div className='risk-region-container nexus-flex-col'>
                        <label className='map-label'>{t('homePage.topRisksByRegion')}</label>
                        <div className='nexus-flex-row map-table-container'>
                            <div className='map-container'>
                                <MapComponent
                                    data={mapData}
                                    className={'map'}
                                    key={(mapData.length ? 'risk-map' : '') + riskType + category.id}
                                />
                                <div className='nexus-flex-row map-legend'>
                                    <ColorRange
                                        showRange={true}
                                        className='legend-item'
                                        data={Object.keys(RISK_LEVELS_COLORS).map((key) => ({
                                            color: RISK_LEVELS_COLORS[key],
                                        }))}
                                    />
                                    <Chip
                                        className='legend-item'
                                        color={NO_DATA_COLOR}
                                        label='No Data'
                                        width='16px'
                                        height='16px'
                                    />
                                </div>
                            </div>
                            <CustomTable
                                key={riskRegionData.length + category}
                                columnsDef={colDetails}
                                data={rowsTransformation(riskRegionData)}
                                className='residual-risk-table'
                                onRowClick={(e) => getrRiskByRegionId(e)}
                            />
                        </div>
                    </div>
                </CardComponent>
            </div>
            <div className='second-section nexus-flex-row'>
                <div className='second-a-section'>
                    <CardComponent className={'lob'}>
                        <div className='lob-card-title'>{t('homePage.topRisksByLineOfBusiness')}</div>
                        <CustomTable
                            key={allRiskSectorData.length + category}
                            columnsDef={colDetailLOB}
                            data={rowsTransformationLOB(allRiskSectorData)}
                            className='lob-risk-table'
                            onRowClick={(e) => getRiskBusinessId(e)}
                        />
                    </CardComponent>
                </div>
                <div className='nexus-flex-col second-b-section'>
                    <div className='second-b1-section' ref={lineChartContainerRef}>
                        <CardComponent className={'risk-line-chart'}>
                            <label className='risk-line-title'>{t('homePage.TrendingRiskExposurebyCategory')}</label>
                            <div>
                                <LineChart
                                    width={lineChartContainerRef.current?.clientWidth || 600}
                                    height={350}
                                    datasets={riskLineChart.data}
                                    range={riskLineChart.range}
                                />
                                <ColorRange
                                    className='line-chart-legend'
                                    data={Object.keys(LINE_CHART_COLORS).map((key) => ({
                                        className: 'line-chart-legend-item',
                                        color: LINE_CHART_COLORS[key],
                                        label: t('homePage.' + key),
                                    }))}
                                    radius={'5px'}
                                />
                            </div>
                        </CardComponent>
                    </div>
                    <div className='second-b2-section'>
                        <CardComponent className={'prc-charts'}>
                            <div className='prc-card-chart-title'>{t('homePage.PRCChartTitle')}</div>
                            <div className='prc-card-chart-sub-title'>
                                {t('homePage.totalProcesses')}
                                {processesCount}
                            </div>
                            <div className='nexus-flex-row doughnuts-container'>
                                <div className='nexus-flex-col risk-doughnut-chart doughnut-chart'>
                                    <div className='chart-title'>{t('homePage.risks')}</div>

                                    <DonutChart
                                        className='doughnut'
                                        width={250}
                                        height={250}
                                        innerRadius={60}
                                        data={riskDounutData?.data ?? []}
                                        title={riskDounutData?.count}
                                        border={{ color: 'white', width: 1 }}
                                    />
                                    <div className='nexus-flex-row map-legend'>
                                        <ColorRange
                                            className='legend-item'
                                            data={Object.keys(RISK_LEVELS_COLORS).map((key) => ({
                                                color: RISK_LEVELS_COLORS[key],
                                            }))}
                                            radius={'5px'}
                                            showRange={true}
                                        />
                                    </div>

                                    <Link
                                        to={'/inventory/risks'}
                                        className='navigation-link'
                                        onClick={() => {
                                            addCategoryFilter('Risks');
                                        }}
                                    >
                                        <div>{t('homePage.viewRiskInventory')}</div>
                                        <IconComponent src={arrow} className='icon' />
                                    </Link>
                                </div>
                                <div className='partition-line'></div>
                                <div className='nexus-flex-col control-doughnut-chart doughnut-chart'>
                                    <div className='chart-title'>{t('homePage.controls')}</div>

                                    <DonutChart
                                        className='doughnut'
                                        width={250}
                                        height={250}
                                        innerRadius={60}
                                        data={controlEffectivenessData.data ?? []}
                                        title={controlEffectivenessData?.count}
                                        border={{ color: 'white', width: 1 }}
                                    />
                                    <ColorRange
                                        className='legend-item'
                                        data={Object.keys(CONTROL_EFFECTIVENESS_COLORS).map((key) => ({
                                            className: 'control-legend',
                                            color: CONTROL_EFFECTIVENESS_COLORS[key],
                                            label: t('homePage.' + key),
                                        }))}
                                        radius={'5px'}
                                    />
                                    <Link
                                        to={'/inventory/controls'}
                                        className='navigation-link'
                                        onClick={() => {
                                            addCategoryFilter('Controls');
                                        }}
                                    >
                                        <div>{t('homePage.viewControlInventory')}</div>
                                        <IconComponent src={arrow} className='icon' />
                                    </Link>
                                </div>
                            </div>
                        </CardComponent>
                    </div>
                </div>
            </div>

            <DrawerComponent
                title={regionIData?.name + ' ' + t('homePage.Summary')}
                onClose={() => {
                    setOpenRiskSummary(false);
                    setopenLobSummary(false);
                    setRiskTablistData([]);
                    setprocessTablistData([]);
                }}
                open={openRiskSummary}
            >
                <div className='third-label-section nexus-flex-row'>
                    <div className='nexus-flex-col third-label-container'>
                        <label className='third-label-type'>
                            {t(riskType === 'Residual' ? 'riskDetailPage.residualRisk' : 'riskDetailPage.inherentRisk')}
                        </label>
                        <div className='nexus-flex-row third-label-types'>
                            <ChipArrow
                                text={
                                    riskType === 'Residual'
                                        ? regionIData.residualRiskLevel
                                        : regionIData.inherentRiskLevel
                                }
                                bgColor={
                                    RISK_LEVELS_COLORS[
                                        riskType === 'Residual'
                                            ? regionIData.residualRiskLevel
                                            : regionIData.inherentRiskLevel
                                    ]
                                }
                                color={
                                    riskType === 'Residual'
                                        ? regionIData.residualRiskLevel === 5
                                            ? 'white'
                                            : 'black'
                                        : regionIData.inherentRiskLevel === 5
                                        ? 'white'
                                        : 'black'
                                }
                                arrowDirection={
                                    riskType === 'Residual' ? regionIData.residualTrend : regionIData.inherentTrend
                                }
                            />
                        </div>
                    </div>
                    <div className='nexus-flex-col third-label-container'>
                        <label className='third-label-type'>{t('riskDetailPage.previousScore')}</label>
                        <div className='nexus-flex-row third-label-types'>
                            {riskType === 'Residual' ? regionID.residualPrevRiskLevel : regionID.inherentPrevRiskLevel}
                        </div>
                    </div>
                </div>
                <CardComponent className='drawer-card'>
                    {isLoading ? (
                        <LoaderComponent show={true}></LoaderComponent>
                    ) : (
                        <TabsComponent
                            key={
                                riskType +
                                tabName +
                                (riskTablistData.length ? 'risk-tab' : '') +
                                (processTablistData.length ? ' process-tab' : '') +
                                (controlsTablistData.length ? ' control-tab' : '')
                            }
                            tabOption={tabOption.map((option) => {
                                if (option.tabName === tabName) {
                                    option.isActive = true;
                                } else {
                                    option.isActive = false;
                                }
                                return option;
                            })}
                            className='risk-summary-tab'
                            callBack={(tabName) => {
                                setTabName(tabName.tabName);
                            }}
                        />
                    )}
                </CardComponent>
                <div className='view-risk'>
                    <ButtonComponent
                        extraClass={'tab-section-btn'}
                        type={['nexus-btn nexus-btn-medium']}
                        label={tabName ? `View ${tabName}` : 'View Risks'}
                        click={setRiskFilterData}
                    />
                </div>
            </DrawerComponent>
            <DrawerComponent
                title={businessData?.businessUnitName + ' ' + t('homePage.Summary')}
                onClose={() => {
                    setopenLobSummary(false);
                    setOpenRiskSummary(false);
                    setRiskTablistData([]);
                    setprocessTablistData([]);
                }}
                open={openLobSummary}
            >
                <div className='third-label-section nexus-flex-row'>
                    <div className='nexus-flex-col third-label-container'>
                        <label className='third-label-type'>
                            {t(riskType === 'Residual' ? 'riskDetailPage.residualRisk' : 'riskDetailPage.inherentRisk')}
                        </label>
                        <div className='nexus-flex-row third-label-types'>
                            <ChipArrow
                                text={
                                    riskType === 'Residual'
                                        ? businessData?.residualRiskLevel?.props?.text
                                        : businessData?.inherentRiskLevel?.props?.text
                                }
                                bgColor={
                                    RISK_LEVELS_COLORS[
                                        riskType === 'Residual'
                                            ? businessData?.residualRiskLevel?.props?.text
                                            : businessData?.inherentRiskLevel?.props?.text
                                    ]
                                }
                                color={
                                    riskType === 'Residual'
                                        ? businessData?.residualRiskLevel?.props?.text === 5
                                            ? 'white'
                                            : 'black'
                                        : businessData?.inherentRiskLevel?.props?.text === 5
                                        ? 'white'
                                        : 'black'
                                }
                            />
                        </div>
                    </div>
                    <div className='nexus-flex-col third-label-container'>
                        <label className='third-label-type'>{t('riskDetailPage.previousScore')}</label>
                        <div className='nexus-flex-row third-label-types'>
                            {riskType === 'Residual'
                                ? businessData?.residualPrevRiskLevel
                                : businessData?.inherentPrevRiskLevel}
                        </div>
                    </div>
                </div>
                <CardComponent className='drawer-card'>
                    {isLoading ? (
                        <LoaderComponent show={true}></LoaderComponent>
                    ) : (
                        <TabsComponent
                            key={
                                riskType +
                                tabName +
                                (riskTablistData.length ? 'risk-tab' : '') +
                                (processTablistData.length ? ' process-tab' : '') +
                                (controlsTablistData.length ? ' control-tab' : '')
                            }
                            tabOption={tabOption.map((option) => {
                                if (option.tabName === tabName) {
                                    option.isActive = true;
                                } else {
                                    option.isActive = false;
                                }
                                return option;
                            })}
                            className='risk-summary-tab'
                            callBack={(tabName) => {
                                setTabName(tabName.tabName);
                            }}
                        />
                    )}
                </CardComponent>
                <div className='view-risk'>
                    <ButtonComponent
                        extraClass={'tab-section-btn'}
                        type={['nexus-btn nexus-btn-medium']}
                        label={tabName ? `View ${tabName}` : 'View Risks'}
                        click={setFilterDataLOB}
                    />
                </div>
            </DrawerComponent>
        </div>
    );
}

export default Home;
