/* eslint-disable sort-keys */
//import { removeMessage } from 'utils/actionUtils';
import { createAppSlice } from 'store/sliceHelper';
import {
    RisksApiFactory,
    RiskDTO,
    RiskHistoryLevelPageDTO,
    ControlsApiFactory,
    ControlPageDTO,
    AggregationsForControlsApiFactory,
    AggregationsForRisksApiFactory,
    IssuesApiFactory,
    AuditsApiFactory,
    AggregationForAuditApiFactory,
    AggregationsForTestingApiFactory,
    TestingApiFactory,
} from 'app/api/dra-rdh-service';
import { ReducerCreators } from '@reduxjs/toolkit';
import { rdhApiConfig, blApiConfig } from 'constants/apiConstants';
import { RisksApiFactory as BlRisksApiFactory } from 'app/api/dra-bl-service';

const risksApiFactory = RisksApiFactory(blApiConfig);
const controlsApiFactory = ControlsApiFactory(rdhApiConfig);
const risksApiFactoryDraData = RisksApiFactory(rdhApiConfig);
export const blRisksApiFactory = BlRisksApiFactory(blApiConfig);
const aggregationsForControlsApiFactory = AggregationsForControlsApiFactory(rdhApiConfig);
const aggregationsForRiskApiFactory = AggregationsForRisksApiFactory(rdhApiConfig);
const aggregationsForAuditApiFactory = AggregationForAuditApiFactory(rdhApiConfig);
const issuesApiFactory = IssuesApiFactory(rdhApiConfig);
const auditsApiFactory = AuditsApiFactory(rdhApiConfig);
const aggregationForTestingApiFactory = AggregationsForTestingApiFactory(rdhApiConfig);
const testingApiFactory = TestingApiFactory(rdhApiConfig);

type GetRiskByIdParams = {
    riskId: Array<string>;
    size: number;
    page: number;
    sort: Array<string>;
};
export const INITIAL_STATE_HOME: any = {
    RiskHistoryLevels: {},
    breadcrumbData: [{ isEnabled: true, name: 'Dashboard', path: '/home' }],
    getControlbyIds: {},
    getRiskByRiskID: {},
};
type homeState = Readonly<typeof INITIAL_STATE_HOME>;
export const homeSliceReducer = createAppSlice({
    initialState: INITIAL_STATE_HOME as homeState,
    name: 'riskDetailData',
    reducers: (create: ReducerCreators<any>) => {
        return {
            getAllRiskHistoryLevels: create.asyncThunk(
                async (riskId: any, { rejectWithValue }) => {
                    try {
                        const response: { data: RiskHistoryLevelPageDTO } =
                            await risksApiFactoryDraData.getAllRiskHistoryLevels(riskId);
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.RiskHistoryLevels = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                        //state.messages = removeMessage(state, 'getAllRiskHistoryLevels');
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),

            getAllRiskHistoryLevelsByYear: create.asyncThunk(
                async ({ riskId, year, page, size, sort }, { rejectWithValue }) => {
                    try {
                        const response: { data: RiskHistoryLevelPageDTO } =
                            await risksApiFactoryDraData.getHistoryLevelsByYear(riskId, year, page, size, sort);
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.RiskHistoryLevelsByYear = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                        //state.messages = removeMessage(state, 'getAllRiskHistoryLevels');
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),
            getRiskByRiskIdInfo: create.asyncThunk(
                async (riskId: string, { rejectWithValue }) => {
                    try {
                        const response: { data: RiskDTO } = await risksApiFactory.getRisk(riskId);
                        return response;
                    } catch (error) {
                        rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.getRiskByRiskID = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                        //state.messages = removeMessage(state, 'getRiskByRiskIdInfo');
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),

            getIssuesByRiskId: create.asyncThunk(
                async ({ riskId, severity, page, size, sort, options }, { rejectWithValue }) => {
                    try {
                        const response: { data: any } = await issuesApiFactory.getIssuesByRisk(
                            riskId,
                            severity,
                            page,
                            size,
                            sort,
                            options,
                        );
                        return response;
                    } catch (error) {
                        rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.issueByRiskID = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                        //state.messages = removeMessage(state, 'getRiskByRiskIdInfo');
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),

            getControlAggregrateByRisk: create.asyncThunk(
                async (riskID, { rejectWithValue }) => {
                    try {
                        const response = await aggregationsForControlsApiFactory.getControlAggregateByRiskId(riskID);
                        return response;
                    } catch (error) {
                        rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.controlAggregateByRisk = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),

            getControlByriskId: create.asyncThunk(
                async ({ riskId, page, size, sort }: GetRiskByIdParams, { rejectWithValue }) => {
                    try {
                        const response: { data: ControlPageDTO } = await controlsApiFactory.getAllControls(
                            [''],
                            [''],
                            [''],
                            [''],
                            riskId,
                            [],
                            page,
                            size,
                            sort,
                        );
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.getControlbyIds = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),

            getIssuesAggregateByRiskId: create.asyncThunk(
                async (riskID, { rejectWithValue }) => {
                    try {
                        const response = await aggregationsForRiskApiFactory.getIssuesAggregateByRisksId(riskID);
                        return response;
                    } catch (error) {
                        rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.issuesByRisk = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),

            getAuditsHistoryByRiskId: create.asyncThunk(
                async ({ riskId, page, size, sort, options }, { rejectWithValue }) => {
                    try {
                        const response = await auditsApiFactory.getAuditsHistoryByRiskId(
                            riskId,
                            page,
                            size,
                            sort,
                            options,
                        );
                        return response;
                    } catch (error) {
                        rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.auditsHistory = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),

            getAuditsHistoryScoreByRiskId: create.asyncThunk(
                async (riskID, { rejectWithValue }) => {
                    try {
                        const response = await aggregationsForAuditApiFactory.getAuditHistoryScore(riskID);
                        return response;
                    } catch (error) {
                        rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.auditsHistoryScore = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),

            getTestCaseByRiskId: create.asyncThunk(
                async (riskID, { rejectWithValue }) => {
                    try {
                        const response = await aggregationForTestingApiFactory.getTestCasesByRisksId(riskID);
                        return response;
                    } catch (error) {
                        rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.testCases = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),

            getallTestCases: create.asyncThunk(
                async ({ riskId, page, size, sort, options }, { rejectWithValue }) => {
                    try {
                        const response = await testingApiFactory.getTestCases(riskId, page, size, sort, options);
                        return response;
                    } catch (error) {
                        rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.alltestCases = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),
            getFinancialAndOperationalAggregateByRiskId: create.asyncThunk(
                async (riskID, { rejectWithValue }) => {
                    try {
                        const response = await aggregationsForRiskApiFactory.getFinancialAndOperationalByRiskId(riskID);
                        return response;
                    } catch (error) {
                        rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.finacialAndOperationalScore = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),

            getKrisAggregateByRiskId: create.asyncThunk(
                async (riskID, { rejectWithValue }) => {
                    try {
                        const response = await aggregationsForRiskApiFactory.getKrisAggregateByRiskId(riskID);
                        return response;
                    } catch (error) {
                        rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.KrisAggregateScore = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),

            getKrisByRiskId: create.asyncThunk(
                async (riskID, { rejectWithValue }) => {
                    try {
                        const response = await aggregationsForRiskApiFactory.getKrisByRiskId(riskID);
                        return response;
                    } catch (error) {
                        rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.KrisScore = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),

            getQualitativeAggregateByRiskId: create.asyncThunk(
                async (riskID, { rejectWithValue }) => {
                    try {
                        const response = await aggregationsForRiskApiFactory.getQualitativeAggregateByRiskId(riskID);
                        return response;
                    } catch (error) {
                        rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.QualitativeAggregateScore = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),

            updateBreadcrumb: create.reducer<any>((state, action: any) => {
                state.breadcrumbData = action.payload;
            }),
        };
    },
});
export const {
    getRiskByRiskIdInfo,
    getAllRiskHistoryLevels,
    getControlByriskId,
    updateBreadcrumb,
    getControlAggregrateByRisk,
    getIssuesByRiskId,
    getIssuesAggregateByRiskId,
    getAuditsHistoryByRiskId,
    getAuditsHistoryScoreByRiskId,
    getTestCaseByRiskId,
    getallTestCases,
    getAllRiskHistoryLevelsByYear,
    getFinancialAndOperationalAggregateByRiskId,
    getKrisAggregateByRiskId,
    getKrisByRiskId,
    getQualitativeAggregateByRiskId,
} = homeSliceReducer.actions;
export default homeSliceReducer.reducer;
